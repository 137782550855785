<template>
  <div class="container">
  <div class="d-flex flex-column flex-root">
    <div style="display: flex;justify-content: center;margin-top: 40px;">
      <img class="" src="../../../../public/media/svg/moon-assets/Logo.svg"  />
    </div>
    <div
      class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white" style="margin-top:70px"
      id="kt_login"
    >
      <!--end::Content-->
      <div
        class="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat   img moon-bg" 
      >
        <div style="width:100%">
            <img class="logo" src="../../../../public/media/svg/moon-assets/loginBanner.svg"  />
          </div>
        <!--end: Aside Container -->
      </div>
      <!--begin::Content-->
      <div
        class="flex-row-fluid d-flex overflow-scroll" 
      >
        <div class="d-flex flex-column-fluid flex-center mt-3 mt-lg-0" style="position: relative;top: 0%;">
          <router-view></router-view>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import { mapState } from "vuex";

export default {
  name: "auth",
  methods: {},
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
  },
};
</script>
<style lang="scss" scoped>
.moon-bg{
  background: #fff;
}
@media only screen and (max-width: 600px) {
  .img {
    height: 40%;
    position: relative;
  }
  .logo {
    width: 100% !important;
  }
}
.logo {
  position: relative;
  top: 0%;
  width: 550px;
  height: 420px;
}
@media (max-width: 1600px){
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 100% !important;
}
}
</style>
